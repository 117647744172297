import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import check from '@/assets/icon/check.svg';
import checkWhite from '@/assets/icon/check-white.svg';
import search from '@/assets/icon/search.svg';
import searchDark from '@/assets/icon/search-dark.svg';
import { useIsDarkModeContext, useSelectedNetworkContext } from '@/context';
import { getImage, getSelectableNetwork } from '@/utils';
import { supportChains } from '@/index';
import { getL1NetworkChainIds, getNetworkInfoByChainId } from '@pheasant-network/pheasant-sdk';
const NetworkModalContent = ({ setIsOpenNetworkModal, networkFlag, selectFromNetwork, selectedToNetworkHandler, setIsFocusOut, }) => {
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    const { isDarkMode } = useIsDarkModeContext();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredChains, setFilteredChains] = useState(supportChains);
    const filterChain = (chain) => {
        if (selectedFromNetwork.id === 0 && selectedToNetwork.id === 0)
            return true;
        const selectedNetwork = networkFlag === 'to' ? selectedFromNetwork : selectedToNetwork;
        if (selectedNetwork.id === 0)
            return;
        const selectableNetwork = getSelectableNetwork(chain.id);
        const pairNetworkId = getNetworkInfoByChainId(selectedNetwork.networkID).pairNetworkId;
        const selectablePairNetworkId = getNetworkInfoByChainId(chain.id).pairNetworkId;
        if (isMainnet) {
            return true;
        }
        if (selectedNetwork.isL2) {
            return (selectableNetwork.isL2 || selectableNetwork.networkID === pairNetworkId);
        }
        else if (getL1NetworkChainIds().includes(selectedNetwork.networkID)) {
            return (!getL1NetworkChainIds().includes(selectableNetwork.networkID) &&
                selectedNetwork.networkID === selectablePairNetworkId);
        }
        else {
            return true;
        }
    };
    const displayChainName = (chain) => {
        if (chain.id === 59144)
            return 'Linea';
        return chain.name;
    };
    const onClick = (chain) => {
        if (networkFlag === 'from' &&
            (chain.id === selectedToNetwork?.networkID || !filterChain(chain))) {
            setSelectedToNetwork(getSelectableNetwork(0));
            setIsOpenNetworkModal(false);
        }
        if (networkFlag === 'from') {
            const selectableFromNetwork = getSelectableNetwork(chain.id);
            setSelectedFromNetwork(selectableFromNetwork);
            selectFromNetwork(selectableFromNetwork);
        }
        else if (networkFlag === 'to') {
            const selectableToNetwork = getSelectableNetwork(chain.id);
            setSelectedToNetwork(selectableToNetwork);
            selectedToNetworkHandler();
        }
        setIsFocusOut(true);
        setIsOpenNetworkModal(false);
    };
    const { selectedFromNetwork, setSelectedFromNetwork, selectedToNetwork, setSelectedToNetwork, } = useSelectedNetworkContext();
    useEffect(() => {
        if (supportChains.length > 0) {
            const filtered = networkFlag === 'to'
                ? supportChains
                    .filter((chain) => chain.id !== selectedFromNetwork.networkID)
                    .filter((chain) => filterChain(chain))
                    .filter((chain) => chain.name.toLowerCase().includes(searchQuery.toLowerCase()))
                : supportChains.filter((chain) => chain.name.toLowerCase().includes(searchQuery.toLowerCase()));
            setFilteredChains(filtered);
        }
    }, [supportChains, searchQuery, selectedFromNetwork, selectedToNetwork]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "network-search relative mx-auto mb-2", role: "search", children: [_jsx("input", { className: "border-2 dark:border-custom-black h-10 px-5 pl-16 rounded-lg text-sm focus:outline-none dark:bg-custom-lighter", type: "search", name: "search", placeholder: "Search Network", value: searchQuery, onChange: (e) => setSearchQuery(e.target.value), autoComplete: "off", "aria-label": "Search Network" }), _jsx("div", { className: "absolute left-0 top-0 mt-2 ml-4", children: _jsx("img", { className: "inline", src: isDarkMode ? searchDark : search, alt: "Search Icon", style: {
                                width: '1.5rem',
                                height: '1.5rem',
                            } }) })] }), _jsx("div", { className: "max-h-[300px] min-h-[300px] overflow-y-scroll dark:text-custom-white network-list-container", children: filteredChains.map((chain) => (_jsx("span", { onClick: () => onClick(chain), style: { cursor: 'pointer' }, children: _jsx("div", { className: "network-list flex justify-between items-center p-2 space-x-2 space-y-2 ", children: _jsxs("button", { type: "button", className: `font-medium py-2 pr-4 flex md:px-2 ${chain.id ===
                                (networkFlag === 'from'
                                    ? selectedFromNetwork?.networkID
                                    : selectedToNetwork?.networkID)
                                ? 'pl-2'
                                : 'pl-8'}`, "aria-label": `Select ${displayChainName(chain)}`, children: [chain.id ===
                                    (networkFlag === 'from'
                                        ? selectedFromNetwork?.networkID
                                        : selectedToNetwork?.networkID) && (_jsx("img", { src: isDarkMode ? checkWhite : check, alt: "Check Icon", className: "py-1 pr-1", style: {
                                        width: `${getSelectableNetwork(chain.id).width}rem`,
                                        height: `${getSelectableNetwork(chain.id).height}rem`,
                                        ...(getSelectableNetwork(chain.id).id === 5 ||
                                            getSelectableNetwork(chain.id).id === 6
                                            ? {
                                                maxWidth: 'none',
                                                transform: 'translateX(-0.2rem) translateY(-0.2rem)',
                                            }
                                            : getSelectableNetwork(chain.id).id === 12
                                                ? {
                                                    maxWidth: 'none',
                                                    transform: 'translateX(-0.4rem)',
                                                }
                                                : {}),
                                    } })), _jsx("div", { className: "w-6 mr-2 min-w-0 text-center", children: _jsx("img", { className: "inline", src: getImage(getSelectableNetwork(chain.id).img), alt: getSelectableNetwork(chain.id).label, style: {
                                            width: `${getSelectableNetwork(chain.id).width}rem`,
                                            height: `${getSelectableNetwork(chain.id).height}rem`,
                                            ...(getSelectableNetwork(chain.id).id === 5 ||
                                                getSelectableNetwork(chain.id).id === 6
                                                ? {
                                                    maxWidth: 'none',
                                                    transform: 'translateX(-0.2rem) translateY(-0.2rem)',
                                                }
                                                : getSelectableNetwork(chain.id).id === 12
                                                    ? {
                                                        maxWidth: 'none',
                                                        transform: 'translateX(-0.4rem)',
                                                    }
                                                    : {}),
                                        } }) }), _jsx("span", { className: "whitespace-nowrap min-w-0", children: displayChainName(chain) })] }) }) }, chain.id))) })] }));
};
export default NetworkModalContent;
