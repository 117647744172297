import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import '@/domain/main/footer.css';
import { useState } from 'react';
import { useIsUpwardTradeContext, usePhaseContext, useSelectedNetworkContext, } from '@/context';
import { useAccount } from 'wagmi';
import WalletModal from '@/components/WalletModal';
import { getNonNativeNetworkChainIds } from '@pheasant-network/pheasant-sdk';
const Footer = ({ isApproved, isFirstFeeCalculated, txhash, prepare, executeTradeTemporarily, executeTrade, approve, isInitialized, isTemporarySetting, setAmount, setPlaceholder, }) => {
    const { selectedFromNetwork, selectedToNetwork } = useSelectedNetworkContext();
    const { isUpwardTrade } = useIsUpwardTradeContext();
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const { phase } = usePhaseContext();
    const { isConnected } = useAccount();
    return (_jsxs(_Fragment, { children: [!isConnected ? (_jsx("button", { type: "submit", className: "button relative action-button w-full ml-2 z-0", onClick: () => {
                    setIsWalletModalOpen(true);
                }, "aria-label": "Connect Wallet", children: _jsx("span", { children: "Connect Wallet" }) })) : (_jsx(_Fragment, { children: isUpwardTrade ? (_jsx(_Fragment, { children: _jsx("button", { disabled: !isConnected ||
                            !isFirstFeeCalculated ||
                            !isInitialized ||
                            selectedToNetwork.id === 0 ||
                            selectedFromNetwork.id === 0 ||
                            isSending, type: "submit", className: "button relative action-button w-full ml-2 z-0", onClick: async () => {
                            try {
                                setIsSending(true);
                                await executeTradeTemporarily();
                                setAmount(null);
                                setPlaceholder('0');
                                setIsSending(false);
                            }
                            catch (error) {
                                console.log(error);
                            }
                        }, "aria-label": "Send", "aria-disabled": !isConnected ||
                            !isFirstFeeCalculated ||
                            !isInitialized ||
                            selectedToNetwork.id === 0 ||
                            selectedFromNetwork.id === 0 ||
                            isSending, children: _jsx("span", { children: "Send" }) }) })) : (_jsx(_Fragment, { children: getNonNativeNetworkChainIds().includes(selectedFromNetwork.networkID) ? (_jsxs(_Fragment, { children: [_jsx("button", { disabled: !isConnected ||
                                    isApproved ||
                                    !isFirstFeeCalculated ||
                                    !isInitialized ||
                                    selectedToNetwork.id === 0, type: "submit", className: "button relative action-button mr-2 z-0", onClick: approve, "aria-label": "Approve", "aria-disabled": !isConnected ||
                                    isApproved ||
                                    !isFirstFeeCalculated ||
                                    !isInitialized ||
                                    selectedToNetwork.id === 0, children: _jsx("span", { children: "Approve" }) }), _jsx("button", { disabled: !isConnected ||
                                    !isApproved ||
                                    !isFirstFeeCalculated ||
                                    !isInitialized ||
                                    selectedToNetwork.id === 0 ||
                                    isSending, type: "submit", className: `button relative action-button ml-2 z-0 ${isApproved && phase === 1 && 'highlight'}`, onClick: async () => {
                                    try {
                                        setIsSending(true);
                                        await executeTrade();
                                        setAmount(null);
                                        setPlaceholder('0');
                                        setIsSending(false);
                                    }
                                    catch (error) {
                                        console.log(error);
                                    }
                                }, "aria-label": "Send", "aria-disabled": !isConnected ||
                                    !isApproved ||
                                    !isFirstFeeCalculated ||
                                    !isInitialized ||
                                    selectedToNetwork.id === 0 ||
                                    isSending, children: _jsx("span", { children: "Send" }) })] })) : (_jsx("button", { disabled: !isConnected ||
                            !isFirstFeeCalculated ||
                            !isInitialized ||
                            selectedToNetwork.id === 0 ||
                            selectedFromNetwork.id === 0 ||
                            isSending, type: "submit", className: "button relative action-button w-full ml-2 z-0", onClick: async () => {
                            try {
                                setIsSending(true);
                                await executeTrade();
                                setAmount(null);
                                setPlaceholder('0');
                                setIsSending(false);
                            }
                            catch (error) {
                                console.log(error);
                            }
                        }, "aria-label": "Send", "aria-disabled": !isConnected ||
                            !isFirstFeeCalculated ||
                            !isInitialized ||
                            selectedToNetwork.id === 0 ||
                            selectedFromNetwork.id === 0 ||
                            isSending, children: _jsx("span", { children: "Send" }) })) })) })), _jsx(WalletModal, { isWalletModalOpen: isWalletModalOpen, setIsWalletModalOpen: setIsWalletModalOpen })] }));
};
export default Footer;
