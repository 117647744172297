import { useEffect, useState } from 'react';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { NETWORKS, constants } from '@/constants';
import { useSelectedNetworkContext } from '@/context';
import { getAllNetworkContractInfo, PheasantNetworkBridgeChild, Web3ServiceBrowserNew as Web3Service, BondManager, PheasantNetworkParameters, } from '@pheasant-network/pheasant-sdk';
import { getSelectableNetwork } from '@/utils';
let web3;
let THRESHOLD_ETH_AMOUNT;
let MINIMUM_ETH_AMOUNT;
export const useInstance = () => {
    const { chain: currentChain } = useNetwork();
    const { isError: isSwitchError } = useSwitchNetwork();
    const { address, isConnected } = useAccount();
    const [thresholds, setThresholds] = useState({});
    const [recipient, setRecipient] = useState('');
    const [isInitialized, setIsInitialized] = useState(false);
    const [bridge_contract_for_call, setBridge_contract_for_call] = useState(null);
    const { selectedFromNetwork, selectedToNetwork, setSelectedNetwork } = useSelectedNetworkContext();
    const [allContractAddressList, setAllContractAddressList] = useState({});
    const [archiveAllContractAddressList, setArchiveAllContractAddressList] = useState({});
    const [isTemporarySetting, setIsTemporarySetting] = useState(false);
    const initialAllForCalls = NETWORKS.reduce((acc, network) => {
        if (network.id !== 0) {
            acc[network.id] = {};
        }
        return acc;
    }, {});
    const [allForCalls, setAllForCalls] = useState(initialAllForCalls);
    useEffect(() => {
        if (isConnected) {
            initialize();
        }
    }, [isConnected]);
    // When network is switched, initialize all
    useEffect(() => {
        if (currentChain) {
            setSelectedNetwork(!currentChain.unsupported && currentChain?.id
                ? getSelectableNetwork(currentChain.id)
                : NETWORKS[0]);
        }
    }, [currentChain, isSwitchError, setSelectedNetwork]);
    const initialize = async () => {
        let ethereumEndpoint = process.env.REACT_APP_MAINNET_ENDPOINT;
        web3 = await Web3Service.init(ethereumEndpoint);
        setTemporarySetting();
        await initializeAll();
    };
    const initializeAll = async () => {
        try {
            console.log('initializeAll');
            setIsInitialized(false);
            await initializeCallEndpoint();
            console.log('Finish the initialization');
            setRecipient(address);
            setIsInitialized(true);
            //setDisputableList(Array(Object.values(tradeList).length).fill(true)) //initialize
        }
        catch (error) {
            console.log(error);
        }
    };
    // unused
    // const initializeSepolia = async () => {
    //   web3_mainnet = await Web3Service.init(
    //     process.env.REACT_APP_SEPOLIA_ENDPOINT,
    //     undefined,
    //   )
    //   setWeb3Mainnet(web3_mainnet)
    // }
    const initializeMainnet = async () => {
        let ethereumEndpoint;
        ethereumEndpoint = process.env.REACT_APP_MAINNET_ENDPOINT;
        const web3_mainnet = await Web3Service.init(ethereumEndpoint);
        allForCalls[1]['web3'] = web3_mainnet;
    };
    const initializeCallEndpoint = async () => {
        const polygonNetworkId = 137;
        const optimismNetworkId = 10;
        const arbitrumNetworkId = 42161;
        const scrollNetworkId = 534352;
        const zkSyncNetworkId = 324;
        const baseNetworkId = 8453;
        const polygonZkEvmNetworkId = 1101;
        const lineaNetworkId = 59144;
        const taikoNetworkId = 167000;
        const mantleNetworkId = 5000;
        const modeNetworkId = 34443;
        const xLayerNetworkId = 196;
        const polygonEndpoint = process.env.REACT_APP_POLYGON_ENDPOINT;
        const optimismEndpoint = process.env.REACT_APP_OPTIMISM_ENDPOINT;
        const arbitrumEndpoint = process.env.REACT_APP_ARBITRUM_ENDPOINT;
        const scrollEndpoint = process.env.REACT_APP_SCROLL_ENDPOINT;
        const zkSyncEndpoint = process.env.REACT_APP_ZK_SYNC_ENDPOINT;
        const baseEndpoint = process.env.REACT_APP_BASE_ENDPOINT;
        const polygonZkEvmEndpoint = process.env.REACT_APP_POLYGON_ZK_EVM_ENDPOINT;
        const lineaEndpoint = process.env.REACT_APP_LINEA_ENDPOINT;
        const taikoEndpoint = process.env.REACT_APP_TAIKO_ENDPOINT;
        const mantleEndpoint = process.env.REACT_APP_MANTLE_ENDPOINT;
        const modeEndpoint = process.env.REACT_APP_MODE_ENDPOINT;
        const xLayerEndpoint = process.env.REACT_APP_XLAYER_ENDPOINT;
        const ethereumProcedures = async () => {
            await initializeMainnet();
        };
        archiveAllContractAddressList['v1'] =
            await getArchiveAllContractAddressList('v1');
        const polygonProcedures = async () => {
            try {
                const web3_polygon = await Web3Service.init(polygonEndpoint);
                allForCalls[3]['web3'] = web3_polygon;
                allContractAddressList['polygon'] = await getAllContractAddressList(polygonNetworkId, 'pheasantNetworkBridgeAddress');
                const polygon_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_polygon, await getLatestContractAddress(polygonNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[3]['bridgeContract'] = polygon_bridge_contract_for_call;
                let polygonThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_polygon, await getLatestContractAddress(polygonNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[3]['bondManager'] = bond_manager_for_call;
                const polygon_parameter_for_call = await PheasantNetworkParameters.init(web3_polygon, await getLatestContractAddress(polygonNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[3]['parameter'] = polygon_parameter_for_call;
                polygonThreshold = await getThresholds(polygon_parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    2: polygonThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const optimismProcedures = async () => {
            try {
                const web3_optimism = await Web3Service.init(optimismEndpoint);
                allContractAddressList['optimism'] = await getAllContractAddressList(optimismNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[2]['web3'] = web3_optimism;
                const optimism_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_optimism, await getLatestContractAddress(optimismNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[2]['bridgeContract'] = optimism_bridge_contract_for_call;
                let optimismThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_optimism, await getLatestContractAddress(optimismNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[2]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_optimism, await getLatestContractAddress(optimismNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[2]['parameter'] = parameter_for_call;
                optimismThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    3: optimismThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const arbitrumProcedures = async () => {
            try {
                const web3_arbitrum = await Web3Service.init(arbitrumEndpoint);
                allContractAddressList['arbitrum'] = await getAllContractAddressList(arbitrumNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[4]['web3'] = web3_arbitrum;
                const arbitrum_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_arbitrum, await getLatestContractAddress(arbitrumNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[4]['bridgeContract'] = arbitrum_bridge_contract_for_call;
                let arbitrumThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_arbitrum, await getLatestContractAddress(arbitrumNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[4]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_arbitrum, await getLatestContractAddress(arbitrumNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[4]['parameter'] = parameter_for_call;
                arbitrumThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    4: arbitrumThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const scrollProcedures = async () => {
            try {
                const web3_scroll = await Web3Service.init(scrollEndpoint);
                allContractAddressList['scroll'] = await getAllContractAddressList(scrollNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[5]['web3'] = web3_scroll;
                const scroll_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_scroll, await getLatestContractAddress(scrollNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[5]['bridgeContract'] = scroll_bridge_contract_for_call;
                let scrollThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_scroll, await getLatestContractAddress(scrollNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[5]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_scroll, await getLatestContractAddress(scrollNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[5]['parameter'] = parameter_for_call;
                scrollThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    5: scrollThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const zkSyncProcedures = async () => {
            try {
                const web3_zkSync = await Web3Service.init(zkSyncEndpoint);
                allContractAddressList['zkSync'] = await getAllContractAddressList(zkSyncNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[6]['web3'] = web3_zkSync;
                const zkSync_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_zkSync, await getLatestContractAddress(zkSyncNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[6]['bridgeContract'] = zkSync_bridge_contract_for_call;
                let zkSyncThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_zkSync, await getLatestContractAddress(zkSyncNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[6]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_zkSync, await getLatestContractAddress(zkSyncNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[6]['parameter'] = parameter_for_call;
                zkSyncThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    6: zkSyncThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const baseProcedures = async () => {
            try {
                const web3_base = await Web3Service.init(baseEndpoint);
                allContractAddressList['base'] = await getAllContractAddressList(baseNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[7]['web3'] = web3_base;
                const base_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_base, await getLatestContractAddress(baseNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[7]['bridgeContract'] = base_bridge_contract_for_call;
                let baseThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_base, await getLatestContractAddress(baseNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[7]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_base, await getLatestContractAddress(baseNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[7]['parameter'] = parameter_for_call;
                baseThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    7: baseThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const polygonZkEvmProcedures = async () => {
            try {
                const web3_polygonZkEvm = await Web3Service.init(polygonZkEvmEndpoint);
                allContractAddressList['polygonZkEvm'] =
                    await getAllContractAddressList(polygonZkEvmNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[8]['web3'] = web3_polygonZkEvm;
                const polygonZkEvm_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_polygonZkEvm, await getLatestContractAddress(polygonZkEvmNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[8]['bridgeContract'] = polygonZkEvm_bridge_contract_for_call;
                let polygonZkEvmThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_polygonZkEvm, await getLatestContractAddress(polygonZkEvmNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[8]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_polygonZkEvm, await getLatestContractAddress(polygonZkEvmNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[8]['parameter'] = parameter_for_call;
                polygonZkEvmThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    8: polygonZkEvmThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const lineaProcedures = async () => {
            try {
                const web3_linea = await Web3Service.init(lineaEndpoint);
                allContractAddressList['linea'] = await getAllContractAddressList(lineaNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[9]['web3'] = web3_linea;
                const linea_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_linea, await getLatestContractAddress(lineaNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[9]['bridgeContract'] = linea_bridge_contract_for_call;
                let lineaThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_linea, await getLatestContractAddress(lineaNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[9]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_linea, await getLatestContractAddress(lineaNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[9]['parameter'] = parameter_for_call;
                lineaThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    9: lineaThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const taikoProcedures = async () => {
            try {
                const web3_taiko = await Web3Service.init(taikoEndpoint);
                allContractAddressList['taiko'] = await getAllContractAddressList(taikoNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[10]['web3'] = web3_taiko;
                const taiko_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_taiko, await getLatestContractAddress(taikoNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[10]['bridgeContract'] = taiko_bridge_contract_for_call;
                let taikoThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_taiko, await getLatestContractAddress(taikoNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[10]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_taiko, await getLatestContractAddress(taikoNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[10]['parameter'] = parameter_for_call;
                taikoThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    10: taikoThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const mantleProcedures = async () => {
            try {
                const web3_mantle = await Web3Service.init(mantleEndpoint);
                allContractAddressList['mantle'] = await getAllContractAddressList(mantleNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[12]['web3'] = web3_mantle;
                const mantle_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_mantle, await getLatestContractAddress(mantleNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[12]['bridgeContract'] = mantle_bridge_contract_for_call;
                let mantleThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_mantle, await getLatestContractAddress(mantleNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[12]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_mantle, await getLatestContractAddress(mantleNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[12]['parameter'] = parameter_for_call;
                mantleThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    12: mantleThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const modeProcedures = async () => {
            try {
                const web3_mode = await Web3Service.init(modeEndpoint);
                allContractAddressList['mode'] = await getAllContractAddressList(modeNetworkId, 'pheasantNetworkBridgeAddress');
                allForCalls[13]['web3'] = web3_mode;
                const mantle_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_mode, await getLatestContractAddress(modeNetworkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[13]['bridgeContract'] = mantle_bridge_contract_for_call;
                let modeThreshold = {};
                const bond_manager_for_call = await BondManager.init(web3_mode, await getLatestContractAddress(modeNetworkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[13]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_mode, await getLatestContractAddress(modeNetworkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[13]['parameter'] = parameter_for_call;
                modeThreshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    13: modeThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const xLayerProcedures = async () => {
            try {
                const web3 = await Web3Service.init(xLayerEndpoint);
                const networkId = xLayerNetworkId;
                const id = 14;
                const name = 'xLayer';
                allContractAddressList[name] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3;
                const bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[id]['bridgeContract'] = bridge_contract_for_call;
                let threshold = {};
                const bond_manager_for_call = await BondManager.init(web3, await getLatestContractAddress(networkId, 'bondManagerAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3, await getLatestContractAddress(networkId, 'parametersAddress'), process.env.REACT_APP_ENV, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                threshold = await getThresholds(parameter_for_call);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        let procedureList = [];
        procedureList = [
            ethereumProcedures(),
            polygonProcedures(),
            optimismProcedures(),
            lineaProcedures(),
            arbitrumProcedures(),
            baseProcedures(),
            polygonZkEvmProcedures(),
            //mantleProcedures(),
            zkSyncProcedures(),
            scrollProcedures(),
            modeProcedures(),
            xLayerProcedures(),
            taikoProcedures(),
        ];
        await Promise.all(procedureList);
    };
    // const getThresholdsOld = async (bridgeContractForCall: any) => {
    //   const threshold = await bridgeContractForCall.getTradeThreshold()
    //   const minimumAmount = await bridgeContractForCall.getTradeMinimumAmount()
    //   THRESHOLD_ETH_AMOUNT = parseFloat(await fromWei(threshold))
    //   MINIMUM_ETH_AMOUNT = parseFloat(await fromWei(minimumAmount))
    //   return {
    //     thresholdETHAmount: THRESHOLD_ETH_AMOUNT,
    //     minimumETHAmount: MINIMUM_ETH_AMOUNT,
    //   }
    // }
    const getThresholds = async (parameterForCall) => {
        const threshold = await parameterForCall.tradeThreshold(constants.ETH_TOKEN_INDEX);
        const minimumAmount = await parameterForCall.tradeMinimumAmount(constants.ETH_TOKEN_INDEX);
        THRESHOLD_ETH_AMOUNT = parseFloat(await fromWei(threshold));
        MINIMUM_ETH_AMOUNT = parseFloat(await fromWei(minimumAmount));
        return {
            thresholdETHAmount: THRESHOLD_ETH_AMOUNT,
            minimumETHAmount: MINIMUM_ETH_AMOUNT,
        };
    };
    const fromWei = async (_amount) => {
        return await web3.fromWei(_amount);
    };
    const setTemporarySetting = () => {
        if (selectedFromNetwork.id == 9 || selectedToNetwork.id == 9) {
            setIsTemporarySetting(false);
        }
        else {
            setIsTemporarySetting(true);
        }
    };
    const getArchiveAllContractAddressList = async (version) => {
        return (await getAllNetworkContractInfo(version, 'all'));
    };
    const getAllContractAddressList = async (networkId, token) => {
        const tokenAddressList = await getAllNetworkContractInfo(process.env.REACT_APP_ENV, 'all');
        return tokenAddressList[networkId][token];
    };
    const getLatestContractAddress = async (networkId, token) => {
        const tokenAddressList = await getAllContractAddressList(networkId, token);
        if (!Array.isArray(tokenAddressList)) {
            return tokenAddressList;
        }
        return tokenAddressList[tokenAddressList.length - 1];
    };
    return {
        web3,
        MINIMUM_ETH_AMOUNT,
        bridge_contract_for_call,
        getLatestContractAddress,
        setTemporarySetting,
        isTemporarySetting,
        isInitialized,
        thresholds,
        allForCalls,
        recipient,
        setRecipient,
        fromWei,
        allContractAddressList,
        archiveAllContractAddressList,
    };
};
