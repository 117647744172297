import axios from 'axios';
import { EthereumProof } from 'ethereum-proof';
import { useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';
import { switchNetwork, fetchBalance } from '@wagmi/core';
import { blockExplorers, constants, NETWORKS, } from '@/constants';
import { useCurrentTxInfo, useIsFinishedTrade, useIsUpwardTradeContext, useModalContext, usePhaseContext, useSelectedNetworkContext, } from '@/context';
import { useInstance } from './useInstance';
import { PheasantSDK, Token, Util, getL1NetworkChainIds, getNetworkInfoByChainId, getNonNativeNetworkChainIds, } from '@pheasant-network/pheasant-sdk';
import { isSupportedNetwork, formatNumberWithFourDecimalPlaces, } from '@/utils';
import { ModalError } from '@/errors/ModalError';
import { getEthersSigner } from '@/utils/ethers';
let UPWARD_DISPUTABLE_PERIOD;
let DOWNWARD_DISPUTABLE_PERIOD;
let l1Balance;
const USE_LOCAL_CONTRACT_DATA = false;
export const useTrade = () => {
    const { isUpwardTrade, setIsUpwardTrade } = useIsUpwardTradeContext();
    const { phase, setPhase } = usePhaseContext();
    const { setCurrentTxInfo } = useCurrentTxInfo();
    const { setIsFinishedTrade } = useIsFinishedTrade();
    const { chain: currentChain } = useNetwork();
    const { address } = useAccount();
    const [amountStr, setAmountStr] = useState('0');
    const [isValidated, setIsValidated] = useState(false);
    const [validateErrorMsg, setValidateErrorMsg] = useState(constants.ZERO_INPUT_ERROR);
    const [txhash, setTxhash] = useState('');
    const [isFeeCalculated, setIsFeeCalculated] = useState(false);
    const [isFirstFeeCalculated, setIsFirstFeeCalculated] = useState(false);
    const [totalFee, setTotalFee] = useState(0);
    const [totalFeeWei, setTotalFeeWei] = useState(0);
    const [calcTimerID, setCalcTimerID] = useState(0);
    let gasPrice = 0;
    const [estimatedReceived, setEstimatedReceived] = useState(0);
    const [isApproved, setIsApproved] = useState(false);
    const [explorerUrl, setExplorerUrl] = useState('');
    const [fromCurrency, setFromCurrency] = useState('ETH');
    const [isProcessing, setIsProcessing] = useState(false);
    const [disputableList, setDisputableList] = useState([]);
    const { setIsProcessingModalOpen, setIsNoticeModalOpen, setIsTimedOutModalOpen, setIsUnknownErrorModalOpen, } = useModalContext();
    const { selectedFromNetwork, setSelectedFromNetwork, selectedToNetwork, setSelectedToNetwork, } = useSelectedNetworkContext();
    const [submittedEvidenceList, setSubmittedEvidenceList] = useState({});
    const [isDeposited, setIsDeposited] = useState(false);
    const { web3, MINIMUM_ETH_AMOUNT, bridge_contract_for_call, setTemporarySetting, isTemporarySetting, thresholds, isInitialized, allForCalls, recipient, setRecipient, } = useInstance();
    const resetInput = () => {
        setIsApproved(false);
        setIsDeposited(false);
        setTxhash('');
        setAmountStr('0');
        setRecipient(address);
        setTotalFee(0);
        setEstimatedReceived(0);
        setPhase(0);
    };
    const getExplorerUrl = (_hash) => {
        const r = Object.entries(blockExplorers).find(([key]) => key == selectedFromNetwork.networkID.toString());
        if (r != null) {
            return r[1] + 'tx/' + _hash;
        }
        return '';
    };
    const composeExplorerUrl = (_hash) => {
        const r = Object.entries(blockExplorers).find(([key]) => key == selectedFromNetwork.networkID.toString());
        if (r != null) {
            setExplorerUrl(r[1] + 'tx/' + _hash);
        }
    };
    const calcUpwardEstimateReceived = async () => {
        if (selectedToNetwork.id === 0)
            return;
        const _amount = convertUndefinedToZero(Number(amountStr));
        setIsFeeCalculated(false);
        let totalFeeWei;
        try {
            totalFeeWei = await allForCalls[selectedToNetwork.id].parameter.getRelayerFee(constants.ETH_TOKEN_INDEX);
            setTotalFeeWei(totalFeeWei);
            setTotalFee(Number((await web3.fromWei(totalFeeWei))));
            const tempEstimatedReceived = Number(Number(await web3.fromWei(Number(BigInt(await web3.toWei(_amount)) - BigInt(totalFeeWei)))).toFixed(7));
            console.log({ tempEstimatedReceived });
            setEstimatedReceived(tempEstimatedReceived < 0 ? 0 : tempEstimatedReceived);
            setIsFirstFeeCalculated(true);
            setIsFeeCalculated(true);
        }
        catch (error) {
            console.error(error);
        }
    };
    const calcEstimateReceived = async () => {
        const _amount = convertUndefinedToZero(Number(amountStr));
        setIsFeeCalculated(false);
        const fromNetwork = getNetworkInfoByChainId(selectedFromNetwork.networkID);
        const toNetwork = getNetworkInfoByChainId(selectedToNetwork.networkID);
        if (!fromNetwork && !toNetwork)
            return;
        const feeWei = await PheasantSDK.calcFee(fromNetwork.chainName, toNetwork.chainName, _amount, USE_LOCAL_CONTRACT_DATA);
        const totalFeeWei = feeWei;
        setTotalFeeWei(feeWei);
        const feeInEther = await web3.fromWei(feeWei);
        setTotalFee(Number(feeInEther));
        const tempEstimatedReceived = Number(Number(await web3.fromWei(Number(BigInt(await web3.toWei(_amount)) - BigInt(totalFeeWei)))).toFixed(7));
        setEstimatedReceived(tempEstimatedReceived < 0 ? 0 : tempEstimatedReceived);
        setIsFirstFeeCalculated(true);
        setIsFeeCalculated(true);
    };
    const getBond = async () => {
        let bondAmount = 0;
        bondAmount = await allForCalls[selectedToNetwork.id].bondManager.getBond(constants.ETH_TOKEN_INDEX);
        return bondAmount;
    };
    const prepare = async () => {
        if (isUpwardTrade) {
            console.log('deposit');
            await deposit();
        }
        else {
            console.log('approve');
            await approve();
        }
    };
    const executeTradeTemporarily = async () => {
        if (isUpwardTrade) {
            console.log('deposit');
            await deposit();
        }
        else {
            console.log('newTrade');
            await newTrade();
        }
    };
    const executeTrade = async () => {
        console.log('newTrade');
        await newTrade();
    };
    const switchCorrectNetwork = async () => {
        if (selectedFromNetwork.networkID !== currentChain.id) {
            return await switchNetwork({ chainId: selectedFromNetwork.networkID });
        }
    };
    const deposit = async () => {
        try {
            const _amount = convertUndefinedToZero(Number(amountStr));
            if (!getL1NetworkChainIds().includes(selectedFromNetwork.networkID) && !getL1NetworkChainIds().includes(currentChain.id)) {
                throw new ModalError('Invalid network', true);
            }
            clearInterval(calcTimerID);
            const chain = await switchCorrectNetwork();
            let result = false;
            const bondAmount = await getBond();
            result = await validate(_amount, chain, phase, bondAmount);
            if (result) {
                setIsValidated(false);
                //if (!isTemporarySetting) {
                setPhase(2);
                // } else {
                //   setPhase(1)
                // }
            }
            else {
                setIsValidated(true);
                return;
            }
            setIsProcessingModalOpen(true);
            setIsProcessing(true);
            const signer = await getEthersSigner(chain ? chain : currentChain);
            const pheasantSDK = await PheasantSDK.init(signer, USE_LOCAL_CONTRACT_DATA);
            const network = getNetworkInfoByChainId(selectedToNetwork.networkID);
            const tx = await pheasantSDK.deposit(_amount, network.chainName, Token.ETH);
            /*const res = await signer.provider.waitForTransaction(tx.transactionHash)
            if (res && res.blockHash && res.blockNumber) {
              const result = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/trades?chainId=${selectedToNetwork.networkID}&operation=accept`);
              console.log(result)
            }*/
            signer.provider.waitForTransaction(tx.transactionHash, 2).then((res) => {
                if (res && res.blockHash && res.blockNumber) {
                    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/trades?chainId=${selectedToNetwork.networkID}&operation=accept`).then((result) => {
                        console.log(result);
                    }).catch((error) => { console.log(error); });
                }
            }).catch((error) => { console.log(error); });
            if (tx && tx.transactionHash) {
                composeExplorerUrl(tx.transactionHash);
                const now = new Date();
                const timestamp = now.getTime();
                const toNetwork = getNetworkInfoByChainId(selectedToNetwork.networkID);
                setCurrentTxInfo({
                    address: address,
                    status: 0,
                    timestamp: timestamp,
                    chainName: toNetwork.chainName,
                    destCode: toNetwork.networkCode,
                    acceptedTxHash: tx.transactionHash,
                    amount: Util.toWei(String(_amount)),
                    fee: totalFeeWei,
                });
                setIsFinishedTrade(true);
            }
            // if (isTemporarySetting) {
            //   setTxhash(tx)
            //   getEstimatedReceived()
            //   setIsDeposited(true)
            // }
            setIsProcessing(false);
            setIsProcessingModalOpen(false);
            setIsNoticeModalOpen(true);
            //if (!isTemporarySetting) {
            resetInput();
            setSelectedFromNetwork(NETWORKS[0]);
            setSelectedToNetwork(NETWORKS[0]);
            setIsFirstFeeCalculated(false);
            //}
        }
        catch (error) {
            console.error(error);
            setIsProcessingModalOpen(false);
            setIsProcessing(false);
            console.log(constants.METAMASK_GENERAL_ERROR);
            if (error instanceof ModalError && error.showModal) {
                clearInterval(calcTimerID);
                setIsUnknownErrorModalOpen(true);
            }
            return;
        }
    };
    const validate = async (_amount, _currentNetwork, _phase, _bondAmount) => {
        if (selectedFromNetwork.id == 0 || selectedToNetwork.id == 0) {
            setValidateErrorMsg(constants.NOT_SELECT_NETWORK);
            return false;
        }
        let selectedNetworkId = 0;
        if (getL1NetworkChainIds().includes(selectedFromNetwork.networkID)) {
            selectedNetworkId = selectedToNetwork.id;
        }
        else if (getL1NetworkChainIds().includes(selectedToNetwork.networkID)) {
            selectedNetworkId = selectedFromNetwork.id;
        }
        else {
            selectedNetworkId = selectedFromNetwork.id;
        }
        console.log('selectedNetworkId: ', selectedNetworkId);
        const threshold = thresholds[selectedNetworkId];
        console.log('threshold: ', threshold);
        const formattedL1Balance = Number(formatNumberWithFourDecimalPlaces(l1Balance.formatted));
        console.log("Ethereum mainnet ETH balance : " + formattedL1Balance);
        if (formattedL1Balance < 0.001) {
            setValidateErrorMsg("You need have more than 0.001 ETH balance on Ethereum mainnet for using the testnet bridging.");
            return false;
        }
        //When upward trades and directly input txhash, skip this check.
        //if (_phase != undefined  && estimatedReceived <= 0) {
        if (estimatedReceived <= 0) {
            setValidateErrorMsg(constants.TOO_LOW_AMOUNT_ERROR);
            return false;
        }
        if (_amount <= 0) {
            setValidateErrorMsg(constants.ZERO_INPUT_ERROR);
            return false;
        }
        else if (_amount < threshold.minimumETHAmount) {
            setValidateErrorMsg(
            // TODO
            `at least ${threshold.minimumETHAmount} ${fromCurrency} per send.`);
            return false;
        }
        else if (_amount > threshold.thresholdETHAmount) {
            const displayThreshold = Math.floor(threshold.thresholdETHAmount * Math.pow(10, 3)) /
                Math.pow(10, 3);
            setValidateErrorMsg(
            // TODO
            `No more than ${displayThreshold} ${fromCurrency} allowed.`);
            return false;
        }
        else if (_currentNetwork && !isSupportedNetwork(_currentNetwork.id)) {
            setValidateErrorMsg(constants.NOT_SUPPORTED_NETWORK_ERROR);
            return false;
        }
        else if (_bondAmount != undefined &&
            _bondAmount < MINIMUM_ETH_AMOUNT * 2.2) {
            // TODO
            setValidateErrorMsg(constants.NOT_ENOUGH_BOND_AMOUNT);
        }
        else if (totalFee > threshold.thresholdETHAmount) {
            setValidateErrorMsg(constants.TOO_HIGH_GAS_ERROR);
            return false;
        }
        else {
            return true;
        }
    };
    const getL1TxInfo = async (_txhash) => {
        const tx = await allForCalls[11]['web3'].getTransaction(_txhash);
        return tx;
    };
    const validateUpwardTrade = async (_amount) => {
        if (txhash == '') {
            setValidateErrorMsg(constants.NO_TXHASH_ERROR);
            return false;
        }
        const tx = await getL1TxInfo(txhash);
        if (tx == null || tx.value != _amount) {
            setValidateErrorMsg(constants.INVALID_AMOUNT_ERROR);
            return false;
        }
        return true;
    };
    const setMaxFeeAndPriorityFee = async (txObj) => {
        // Error occurs when setting with string
        txObj.maxPriorityFeePerGas = Number(txObj.maxPriorityFeePerGas);
        txObj.maxFeePerGas = Number(txObj.maxFeePerGas);
    };
    // TODO CCTP
    // const approveUsdc = async () => {
    //   try {
    //     const contractAddress = '0xfd064a18f3bf249cf1f87fc203e90d8f650f2d63'
    //     const abi = usdcABI
    //     const usdcContract = await web3.getContract(abi, contractAddress)
    //     const amount = 10000000
    //     const GAS_BUFFER = 10000
    //     const pheasantContract = '0x19a16B1477034337Ddf3D39c1b69EB770b936393'
    //     const gas = await usdcContract.methods
    //       .approve(pheasantContract, amount)
    //       .estimateGas({ from: address })
    //     let txObj = {
    //       to: usdcContract.options.address,
    //       from: address,
    //       gas: gas + GAS_BUFFER,
    //     } as any
    //     txObj.data = await usdcContract.methods
    //       .approve(pheasantContract, amount)
    //       .encodeABI()
    //     clearInterval(calcTimerID)
    //     // Approve時にもprocessingModalを表示する(estimatedTimeは削除)
    //     setIsProcessingModalOpen(true)
    //     setIsProcessing(true)
    //     const config = await prepareSendTransaction(txObj)
    //     const { hash } = await sendTransaction(config)
    //     const data = await waitForTransaction({
    //       hash,
    //     })
    //     console.log(data)
    //     setIsApproved(true)
    //     setIsProcessingModalOpen(false)
    //     setIsNoticeModalOpen(true)
    //     setIsProcessing(false)
    //     composeExplorerUrl(hash)
    //   } catch (error) {
    //     setIsProcessingModalOpen(false)
    //     setIsProcessing(false)
    //     console.log(constants.METAMASK_GENERAL_ERROR)
    //     console.log(error)
    //   }
    // }
    const approve = async () => {
        try {
            clearInterval(calcTimerID);
            const chain = await switchCorrectNetwork();
            const _amount = convertUndefinedToZero(Number(amountStr));
            const result = await validate(_amount, chain);
            if (result) {
                setIsValidated(false);
                setPhase(1);
            }
            else {
                setIsValidated(true);
                return;
            }
            // Approve時にもprocessingModalを表示する(estimatedTimeは削除)
            setIsProcessingModalOpen(true);
            setIsProcessing(true);
            // TODO
            // if (selectedFromNetwork.id !== 12) {
            //   setMaxFeeAndPriorityFee(txObj)
            // }
            // if (selectedFromNetwork.id === 14) {
            //   delete txObj.maxPriorityFeePerGas
            //   delete txObj.maxFeePerGas
            // }
            const signer = await getEthersSigner(chain ? chain : currentChain);
            const pheasantSDK = await PheasantSDK.init(signer, USE_LOCAL_CONTRACT_DATA);
            const tx = await pheasantSDK.sendApproval(_amount, Token.WETH);
            setIsApproved(true);
            setIsProcessingModalOpen(false);
            setIsNoticeModalOpen(true);
            setIsProcessing(false);
            if (tx && tx.transactionHash)
                composeExplorerUrl(tx.transactionHash);
        }
        catch (error) {
            setIsProcessingModalOpen(false);
            setIsProcessing(false);
            console.log(constants.METAMASK_GENERAL_ERROR);
            console.log(error);
        }
    };
    const createEvidence = async () => {
        const web3Obj = allForCalls[11]['web3'].getWeb3();
        const ethereumProof = new EthereumProof(web3Obj);
        let evidence = await ethereumProof.composeEvidence(txhash, true);
        evidence = Object.assign(evidence, { txDataSpot: [0, 0] }); //temporary process, delete after audit
        return evidence;
    };
    // TODO CCTP
    // const burn = async () => {
    //   try {
    //     const abi = burnAbi
    //     const contractAddress = '0x19a16B1477034337Ddf3D39c1b69EB770b936393'
    //     const contract = await web3.getContract(abi, contractAddress)
    //     const amount = 10000000
    //     const GAS_BUFFER = 10000
    //     const destinationDomain = 3
    //     const usdcContractAddress = '0xfd064a18f3bf249cf1f87fc203e90d8f650f2d63'
    //     const destinationAddress =
    //       address.slice(0, 2) +
    //       '000000000000000000000000' +
    //       address.slice(2, address.length)
    //     const gas = await contract.methods
    //       .callDepositForBurn(
    //         amount,
    //         destinationDomain,
    //         destinationAddress,
    //         usdcContractAddress,
    //       )
    //       .estimateGas({ from: address })
    //     let txObj = {
    //       to: contract.options.address,
    //       from: address,
    //       gas: gas + GAS_BUFFER,
    //     } as any
    //     txObj.data = await contract.methods
    //       .callDepositForBurn(
    //         amount,
    //         destinationDomain,
    //         destinationAddress,
    //         usdcContractAddress,
    //       )
    //       .encodeABI()
    //     clearInterval(calcTimerID)
    //     setIsProcessingModalOpen(true)
    //     setIsProcessing(true)
    //     const config = await prepareSendTransaction(txObj)
    //     const { hash } = await sendTransaction(config)
    //     const data = await waitForTransaction({
    //       hash,
    //     })
    //     console.log(data)
    //     clearInterval(calcTimerID)
    //     setIsProcessingModalOpen(false)
    //     setIsNoticeModalOpen(true)
    //     setIsProcessing(false)
    //     resetInput()
    //     setSelectedFromNetwork(NETWORKS[0])
    //     setSelectedToNetwork(NETWORKS[0])
    //     composeExplorerUrl(hash)
    //     setIsFirstFeeCalculated(false)
    //   } catch (error) {
    //     setIsProcessingModalOpen(false)
    //     setIsProcessing(false)
    //     console.log(error)
    //     console.log(constants.METAMASK_GENERAL_ERROR)
    //   }
    // }
    const newTrade = async () => {
        try {
            if (selectedFromNetwork.id === 11) {
                throw new ModalError('Invalid network', true);
            }
            clearInterval(calcTimerID);
            const chain = await switchCorrectNetwork();
            const _amount = convertUndefinedToZero(Number(amountStr));
            let isValid = await validate(_amount, chain);
            // if (isTemporarySetting) {
            //   const isValidaUpwardTrade =
            //     (isUpwardTrade && (await validateUpwardTrade(amount))) ||
            //     !isUpwardTrade
            //   isValid = isValidaUpwardTrade && isValid
            // }
            if (totalFeeWei === 0) {
                setValidateErrorMsg(constants.ZERO_FEE_ERROR);
                setIsValidated(true);
                return;
            }
            if (isValid) {
                setIsValidated(false);
                setPhase(2);
            }
            else {
                setIsValidated(true);
                return;
            }
            setIsProcessingModalOpen(true);
            setIsProcessing(true);
            // if (txObj.maxPriorityFeePerGas || txObj.maxFeePerGas) {
            //   setMaxFeeAndPriorityFee(txObj)
            // }
            const signer = await getEthersSigner(chain ? chain : currentChain);
            const signerChainId = await signer.getChainId();
            if (signerChainId !== selectedFromNetwork.networkID) {
                throw new ModalError('Invalid network', true);
            }
            const pheasantSDK = await PheasantSDK.init(signer, USE_LOCAL_CONTRACT_DATA);
            const fromNetwork = getNetworkInfoByChainId(selectedFromNetwork.networkID);
            const toNetwork = getNetworkInfoByChainId(selectedToNetwork.networkID);
            const nonNativeNetworksIds = getNonNativeNetworkChainIds();
            const tx = await pheasantSDK.newTrade(_amount, fromNetwork.chainName, toNetwork.chainName, nonNativeNetworksIds.includes(selectedFromNetwork.networkID)
                ? Token.WETH
                : Token.ETH, {
                customFee: String(totalFeeWei),
            }, false);
            /*const res = await signer.provider.waitForTransaction(tx.transactionHash)
            if (res && res.blockHash && res.blockNumber) {
              const result = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/trades?chainId=${selectedFromNetwork.networkID}&operation=syncData`);
              console.log(result)
            }*/
            signer.provider.waitForTransaction(tx.transactionHash).then((res) => {
                if (res && res.blockHash && res.blockNumber) {
                    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/trades?chainId=${selectedFromNetwork.networkID}&operation=syncData`).then((result) => {
                        console.log(result);
                    }).catch((error) => { console.log(error); });
                }
            }).catch((error) => { console.log(error); });
            // const relayer = getRelayer()
            // const interval = await relayer.getIntervalByNetworkId(
            //   'accept',
            //   selectedFromNetwork.id,
            // )
            // child.getPastEvents('Accept', 'txHash', tx, interval)
            clearInterval(calcTimerID);
            setIsProcessingModalOpen(false);
            setIsNoticeModalOpen(true);
            setIsProcessing(false);
            resetInput();
            setSelectedFromNetwork(NETWORKS[0]);
            setSelectedToNetwork(NETWORKS[0]);
            if (tx && tx.transactionHash) {
                composeExplorerUrl(tx.transactionHash);
                const now = new Date();
                const timestamp = now.getTime();
                setCurrentTxInfo({
                    address: address,
                    status: 0,
                    timestamp: timestamp,
                    chainName: fromNetwork.chainName,
                    destCode: toNetwork.networkCode,
                    newTradeTxHash: tx.transactionHash,
                    amount: Util.toWei(String(_amount)),
                    fee: totalFeeWei,
                });
                setIsFinishedTrade(true);
            }
            setIsFirstFeeCalculated(false);
        }
        catch (error) {
            setIsProcessingModalOpen(false);
            setIsProcessing(false);
            console.log(error);
            console.log(constants.METAMASK_GENERAL_ERROR);
            if (error instanceof ModalError && error.showModal) {
                clearInterval(calcTimerID);
                setIsUnknownErrorModalOpen(true);
            }
            //setIsTimedOutModalOpen(true)
        }
    };
    const cancelTrade = (_index) => {
        // const tx = child.cancelTrade(_index)
        // tx.then((result) => {
        //   getTradeList()
        //   composeExplorerUrl(result.transactionHash)
        // }).catch(() => {
        //   console.log(constants.METAMASK_GENERAL_ERROR)
        // })
    };
    const collectEvidenceList = async () => {
        const withdrawEvents = await bridge_contract_for_call.getPastEvents('Withdraw', 'userAddress', address, 1);
        const newTradeEvents = await bridge_contract_for_call.getPastEvents('NewTrade', 'userAddress', address, 1);
        for (let j = 0; j < withdrawEvents.length; j++) {
            const tx = await allForCalls[3]['web3'].getTransaction(withdrawEvents[j].transactionHash);
            const parsedTxInput = bridge_contract_for_call.parseTransaction(tx.input);
            const txArgs = parsedTxInput.args;
            for (let k = 0; k < txArgs._userTrades.length; k++) {
                if (txArgs._userTrades[k].userAddress.toLowerCase() == address) {
                    submittedEvidenceList[txArgs._userTrades[k].index] =
                        txArgs._evidences[k];
                }
            }
        }
        for (let j = 0; j < newTradeEvents.length; j++) {
            const tx = await allForCalls[3]['web3'].getTransaction(newTradeEvents[j].transactionHash);
            const parsedTxInput = bridge_contract_for_call.parseTransaction(tx.input);
            const txArgs = parsedTxInput.args;
            if (txArgs.evidence != null) {
                submittedEvidenceList[newTradeEvents[j].returnValues.index] =
                    txArgs.evidence;
            }
        }
    };
    const checkDisputable = async () => {
        // await collectEvidenceList()
        // setDisputableList(Array(Object.values(tradeList).length).fill(true))
        // const keys = Object.keys(tradeList)
        // const now = Math.floor(Date.now() / 1000)
        // for (let i = 0; i < keys.length; i++) {
        //   const trade = tradeList[parseInt(keys[i])]
        //   if (
        //     trade.isUpward &&
        //     trade.status == '0' &&
        //     parseInt(trade.timestamp) + UPWARD_DISPUTABLE_PERIOD <= now
        //   ) {
        //     disputableList[i] = false
        //   } else if (
        //     !trade.isUpward &&
        //     trade.status == '1' &&
        //     parseInt(trade.timestamp) + DOWNWARD_DISPUTABLE_PERIOD <= now
        //   ) {
        //     disputableList[i] = false
        //   } else if (!trade.isUpward && trade.status == '2') {
        //     //withdrawの条件
        //     const tradeStruct = {
        //       index: trade.index,
        //       user: trade.user,
        //       tokenTypeIndex: trade.tokenTypeIndex,
        //       amount: trade.amount,
        //       timestamp: trade.timestamp,
        //       to: trade.to,
        //       relayer: trade.relayer,
        //       status: trade.status,
        //       fee: trade.fee,
        //       disputeTimestamp: trade.disputeTimestamp,
        //       isUpward: trade.isUpward,
        //     }
        //     const isValid =
        //       await bridge_contract_for_call.safeCheckEvidenceExceptBlockHash(
        //         tradeStruct,
        //         submittedEvidenceList[trade.index],
        //       )
        //     if (!isValid) {
        //       disputableList[i] = false
        //     }
        //   }
        // }
    };
    const convertUndefinedToZero = (_x) => {
        let x;
        if (typeof _x !== 'number') {
            x = 0;
        }
        else {
            x = _x;
        }
        return x;
    };
    const getEstimatedReceived = async () => {
        console.log('getEstimatedReceived');
        l1Balance = await fetchBalance({
            address: address,
            chainId: 1,
            token: undefined,
        });
        const _amount = convertUndefinedToZero(Number(amountStr));
        setIsValidated(false);
        if (isNaN(_amount) || _amount <= 0) {
            if (calcTimerID != 0) {
                clearInterval(calcTimerID);
                setEstimatedReceived(0);
            }
            return;
        }
        if (isUpwardTrade) {
            calcUpwardEstimateReceived();
            return;
        }
        calcEstimateReceived();
        const timerID = window.setInterval(calcEstimateReceived, 5000);
        setCalcTimerID(timerID);
        window.setTimeout(() => {
            clearInterval(calcTimerID);
        }, 60000);
    };
    const initializeSelectFromNetwork = (selectedFromNetwork) => {
        setIsUpwardTrade(!selectedFromNetwork.isL2);
    };
    const selectFromNetwork = async (selectedFromNetwork) => {
        setTemporarySetting();
        console.log('selectedFromNetwork', selectedFromNetwork);
        initializeSelectFromNetwork(selectedFromNetwork);
        // await getEstimatedReceived()
    };
    const selectedToNetworkHandler = async () => {
        console.log('selectedToNetworkHandler');
        if (selectedToNetwork.id == 0)
            return;
        setTemporarySetting();
        // await getEstimatedReceived()
    };
    return {
        composeExplorerUrl,
        getEstimatedReceived,
        selectFromNetwork,
        totalFee,
        isFeeCalculated,
        selectedToNetworkHandler,
        setAmountStr,
        estimatedReceived,
        isFirstFeeCalculated,
        setIsFirstFeeCalculated,
        prepare,
        executeTradeTemporarily,
        executeTrade,
        approve,
        isApproved,
        explorerUrl,
        isInitialized,
        txhash,
        recipient,
        setRecipient,
        isTemporarySetting,
        calcTimerID,
        isDeposited,
        isProcessing,
        isValidated,
        setIsValidated,
        validateErrorMsg,
        cancelTrade,
        thresholds,
    };
};
