import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { disconnect } from '@wagmi/core';
import { useState } from 'react';
import { useAccount } from 'wagmi';
import arrow from '@/assets/icon/arrow-left.svg';
import arrowWhite from '@/assets/icon/arrow-left-white.svg';
import check from '@/assets/icon/check.svg';
import checkWhite from '@/assets/icon/check-white.svg';
import clipboard from '@/assets/icon/clipboard.svg';
import clipboardWhite from '@/assets/icon/clipboard-white.svg';
import disconnectBlack from '@/assets/icon/disconnect.svg';
import disconnectWhite from '@/assets/icon/disconnect-white.svg';
import { useIsDarkModeContext } from '@/context';
import { getImage, isMobileDevice, shortenAddress } from '@/utils';
import Loading from '@/components/Loading';
import { WALLETS } from '@/constants';
const AccountContent = () => {
    const { isDarkMode } = useIsDarkModeContext();
    const { address, connector } = useAccount();
    const [showDisconnect, setShowDisconnect] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);
    // TODO for walletconnect
    //const { walletImagePath } = useWalletImagePath()
    const copyToClipboard = async (text) => {
        if (navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(text);
                console.log('Copied to clipboard');
            }
            catch (err) {
                console.error('Failed to copy text: ', err);
            }
        }
    };
    return (_jsx("div", { className: "absolute md:relative bg-white dark:bg-custom-light border md:border-none md:bg-inherit border-dark text-clip p-2 md:pt-6 md:p-0 mt-3 md:mt-0 rounded-xl w-72 md:w-full right-0 z-30", children: showDisconnect ? (_jsxs("div", { className: "flex justify-between items-center p-2 md:px-0", children: [_jsx("div", { className: "cursor-pointer", children: _jsx("img", { src: isDarkMode ? arrowWhite : arrow, alt: "Arrow Icon", onClick: () => {
                            setShowDisconnect(false);
                        }, className: "py-1", style: {
                            width: '2rem',
                            height: '2rem',
                        }, tabIndex: 0, onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                setShowDisconnect(false);
                            }
                        } }) }), _jsxs("button", { onClick: disconnect, type: "button", className: "bg-white dark:bg-custom-light md:dark:bg-custom-lighter border dark:border-custom-black rounded-xl text-clip cursor-pointer md:px-2 flex justify-between items-center py-1 px-2 hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black", onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false), "aria-label": "Disconnect", children: [_jsx("img", { src: isDarkMode
                                ? isHovered
                                    ? disconnectBlack
                                    : disconnectWhite
                                : isHovered
                                    ? disconnectWhite
                                    : disconnectBlack, alt: "Disconnect Icon", className: "py-1", style: {
                                width: '1.5rem',
                                height: '1.625rem',
                            } }), "Disconnect"] })] })) : (_jsxs("div", { className: "flex justify-around items-center p-2 md:px-0 space-x-2 space-y-2", children: [_jsxs("div", { className: "flex justify-center items-center pr-4", children: [connector ? (
                        // connector.name === "WalletConnect" ? (
                        //   walletImagePath && (
                        //     <img
                        //     className="h-6 inline mr-2"
                        //     src={getImage(walletImagePath)}
                        //     alt="Wallet Image"
                        //     style={{
                        //       width: !isMobileDevice() ? '1.813rem' : '1.5rem',
                        //       height: !isMobileDevice() ? '1.813rem' : '1.5rem',
                        //     }}
                        //   />
                        //   )
                        // ) : (
                        _jsx("img", { className: "h-6 inline mr-2", src: getImage(WALLETS.find((wallet) => wallet.id === connector.name).img), alt: "Wallet Image", style: {
                                width: !isMobileDevice() ? '1.813rem' : '1.5rem',
                                height: !isMobileDevice() ? '1.813rem' : '1.5rem',
                            } })
                        //)
                        ) : (_jsx(Loading, {})), _jsx("span", { className: "text-base", children: shortenAddress(address, 6) })] }), _jsxs("div", { className: "flex justify-evenly items-center", children: [_jsx("div", { className: "pr-4 pb-2 cursor-pointer", children: _jsx("img", { src: isActive
                                    ? isDarkMode
                                        ? checkWhite
                                        : check
                                    : isDarkMode
                                        ? clipboardWhite
                                        : clipboard, alt: "Clipboard Icon", style: {
                                    width: '1.25rem',
                                    height: '1.25rem',
                                }, onClick: () => {
                                    setIsActive(true);
                                    copyToClipboard(address);
                                    setTimeout(() => {
                                        setIsActive(false);
                                    }, 10000);
                                }, tabIndex: 0, onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        setIsActive(true);
                                        copyToClipboard(address);
                                        setTimeout(() => {
                                            setIsActive(false);
                                        }, 10000);
                                    }
                                }, "aria-label": "Copy Address" }) }), _jsx("div", { className: "pb-2 pr-2 md:pr-0 cursor-pointer", children: _jsx("img", { src: isDarkMode ? disconnectWhite : disconnectBlack, alt: "Disconnect Icon", style: {
                                    width: '1.25rem',
                                    height: '1.25rem',
                                }, onClick: () => setShowDisconnect(true), tabIndex: 0, onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        setShowDisconnect(true);
                                    }
                                }, "aria-label": "Show Disconnect Button" }) })] })] })) }));
};
export default AccountContent;
