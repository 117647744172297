import { currentNetworkList } from '@pheasant-network/pheasant-sdk';
export const constants = {
    TRANSACTION_FEE: 0,
    DISPLAY_DIGIT: 7,
    VALUE: 1,
    ETH_TOKEN_INDEX: 0,
    METAMASK_GENERAL_ERROR: 'The interaction was rejected for some reasons.',
    ZERO_INPUT_ERROR: 'Please input an amount greater than 0.',
    TOO_LOW_AMOUNT_ERROR: 'More than 0.005 ETH needed.',
    TOO_HIGH_GAS_ERROR: 'Temporary gas price is extremely high. The fee exceeds the trade limit. Please try again later.',
    NOT_SUPPORTED_NETWORK_ERROR: 'Not supported Network.',
    NO_TXHASH_ERROR: 'Please input the txhash.',
    INVALID_AMOUNT_ERROR: 'The amount is different from the transaction information entered.',
    INVALID_NETWORK_SELECTION_ERROR: 'Invalid network selection. Please change network manually',
    ADDRESS_EXPLANATION: 'Assets can be sent to a different address on the destination chain.',
    FEE_EXPLANATION: 'Relayer fee is the trading fee. Protocol fee is kept in treasury.',
    NOT_SELECT_NETWORK: 'No network selected.',
    NOT_ENOUGH_BOND_AMOUNT: "This relayer doesn't have enough bond. Pls ask in our discord channel!",
    INSUFFICIENT_ERROR: 'Insufficient funds',
    ZERO_FEE_ERROR: 'invalid zero fee. please wait for fee calculation or reload page',
    UNKNOWN_ERROR: 'Please reload the screen due to a temporary error.',
    NOT_CONNECTED_ERROR: 'Please connect a wallet first.',
    NOT_NATIVE_NETWORK_NAMES: [
        'Polygon',
        'Mumbai',
        'Amoy',
        'Mantle',
        'Mantle(T)',
        'X Layer(T)',
        'X Layer(T)',
    ],
    NOT_NATIVE_NETWORK_SYMBOLS: {
        Polygon: 'MATIC',
        Mumbai: 'MATIC',
        Amoy: 'MATIC',
        Mantle: 'MNT',
        'Mantle(T)': 'MNT',
        'X Layer(T)': 'OKB',
        'X Layer': 'OKB',
    },
    NEWTRADE_ETH_GAS: 259000,
    NEWTRADE_WETH_GAS: 306000,
    APPROVE_GAS: 30000,
    DEPOSIT_GAS: 21000
};
export const loadingImages = ['twins.gif', 'family.gif', 'blink.gif'];
export const loadingDarkModeImages = [
    'twins-dark-mode.gif',
    'family-dark-mode.gif',
    'blink-dark-mode.gif',
];
export const WALLETS = [
    { id: 'MetaMask', img: 'metamask.svg' },
    { id: 'Coinbase Wallet', img: 'coinbase.png' },
    { id: 'WalletConnect', img: 'wallet-connect.png' },
    { id: 'OKX Wallet', img: 'okx-wallet.png' },
    { id: 'Rabby Wallet', img: 'rabby.svg' },
    { id: 'Rainbow Wallet', img: 'rainbow-wallet.svg' },
    { id: 'Trust Wallet', img: 'trust-wallet.svg' },
    { id: 'Zerion Wallet', img: 'zerion-wallet.svg' },
    { id: 'Token Pocket', img: 'token-pocket-wallet.svg' },
    { id: 'Taho Wallet', img: 'taho-wallet.svg' },
    { id: 'Coin98 Wallet', img: 'coin98-wallet.svg' },
    { id: 'Bitget Wallet', img: 'bitget-wallet.svg' },
];
export const WALLET_CONNECT_WALLETS = [
// { id: 'FoxWallet', name: 'FoxWallet', img: 'fox-wallet.svg' },
// { id: 'imToken Wallet', name: 'imToken Wallet', img: 'imtoken-wallet.svg' },
// { id: 'Omni Wallet', name: 'Omni Wallet', img: 'omni-wallet.svg' },
// { id: 'Kraken Wallet', name: 'Kraken Wallet', img: 'kraken-wallet.png' },
];
export const MAIN_NETWORKS = [
    { label: 'Select Network', id: 0, isL2: false },
    {
        label: 'Ethereum',
        id: 1,
        img: 'eth-diamond-purple.svg',
        width: 1.5,
        height: 1.625,
        isL2: false,
        networkID: 1,
    },
    {
        label: 'OP Mainnet',
        id: 2,
        img: 'optimism_logo.png',
        width: 1.5,
        height: 1.5,
        isL2: true,
        networkID: 10,
    },
    {
        label: 'Polygon',
        id: 3,
        img: 'polygon_logo.svg',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 137,
    },
    {
        label: 'Arbitrum',
        id: 4,
        img: 'arbitrum_logo.png',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 42161,
    },
    {
        label: 'Scroll',
        id: 5,
        img: 'scroll_logo.png',
        width: 2,
        height: 1.8,
        isL2: true,
        networkID: 534352,
    },
    {
        label: 'ZkSync Era',
        id: 6,
        img: 'zkSync_logo.svg',
        width: 2,
        height: 2,
        isL2: true,
        networkID: 324,
    },
    {
        label: 'Base',
        id: 7,
        img: 'base_logo.svg',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 8453,
    },
    {
        label: 'Polygon zkEvm',
        id: 8,
        img: 'polygon_logo.svg',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 1101,
    },
    {
        label: 'Linea',
        id: 9,
        img: 'linea_logo.png',
        width: 1.2,
        height: 1.325,
        isL2: true,
        networkID: 59144,
    },
    {
        label: 'Taiko',
        id: 10,
        img: 'taiko_logo.svg',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 167000,
    },
    // {
    //   label: 'Mantle',
    //   id: 12,
    //   img: 'mantle_logo.svg',
    //   width: 1.5,
    //   height: 1.625,
    //   isL2: true,
    //   networkID: 5000,
    // },
    {
        label: 'Mode',
        id: 13,
        img: 'mode_logo.png',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 34443,
    },
    {
        label: 'X Layer',
        id: 14,
        img: 'xlayer_logo.png',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 196,
    },
];
export const TEST_NETWORKS = [
    { label: 'Select Network', id: 0, isL2: false },
    // {
    //   label: 'Goerli',
    //   id: 1,
    //   img: 'eth-diamond-purple.svg',
    //   width: 1.5,
    //   height: 1.625,
    //   isL2: false,
    //   networkID: 5,
    // },
    // {
    //   label: 'Optimism(G)',
    //   id: 2,
    //   img: 'optimism_logo.png',
    //   width: 1.5,
    //   height: 1.5,
    //   isL2: true,
    //   networkID: 420,
    // },
    {
        label: 'Optimism(S)',
        id: 2,
        img: 'optimism_logo.png',
        width: 1.5,
        height: 1.5,
        isL2: true,
        networkID: 11155420,
    },
    {
        label: 'Amoy',
        id: 3,
        img: 'polygon_logo.svg',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 80002,
    },
    // {
    //   label: 'Arbitrum(G)',
    //   id: 4,
    //   img: 'arbitrum_logo.png',
    //   width: 1.5,
    //   height: 1.625,
    //   isL2: true,
    //   networkID: 421613,
    // },
    {
        label: 'Arbitrum(S)',
        id: 4,
        img: 'arbitrum_logo.png',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 421614,
    },
    {
        label: 'Scroll(T)',
        id: 5,
        img: 'scroll_logo.png',
        width: 2,
        height: 1.8,
        isL2: true,
        networkID: 534351,
    },
    {
        label: 'ZkSync Era(T)',
        id: 6,
        img: 'zkSync_logo.svg',
        width: 2,
        height: 2,
        isL2: true,
        networkID: 300,
    },
    // {
    //   label: 'Base(G)',
    //   id: 7,
    //   img: 'base_logo.svg',
    //   width: 1.5,
    //   height: 1.625,
    //   isL2: true,
    //   networkID: 84531,
    // },
    {
        label: 'Base(S)',
        id: 7,
        img: 'base_logo.svg',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 84532,
    },
    {
        label: 'Cardona',
        id: 8,
        img: 'polygon_logo.svg',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 2442,
    },
    {
        label: 'Linea(S)',
        id: 9,
        img: 'linea_logo.png',
        width: 1.2,
        height: 1.325,
        isL2: true,
        networkID: 59141,
    },
    {
        label: 'Taiko(T)',
        id: 10,
        img: 'taiko_logo.svg',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 167009,
    },
    {
        label: 'Sepolia',
        id: 11,
        img: 'eth-diamond-purple.svg',
        width: 1.5,
        height: 1.625,
        isL2: false,
        networkID: 11155111,
    },
    /*{
      label: 'Mantle(T)',
      id: 12,
      img: 'mantle.png',
      width: 2.5,
      height: 1.2,
      isL2: true,
      networkID: 5003,
    },*/
    {
        label: 'Mode(T)',
        id: 13,
        img: 'mode_logo.png',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 919,
    },
    {
        label: 'X Layer(T)',
        id: 14,
        img: 'xlayer_logo.png',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 195,
    },
    {
        label: 'Holesky',
        id: 15,
        img: 'eth-diamond-purple.svg',
        width: 1.5,
        height: 1.625,
        isL2: false,
        networkID: 17000,
    },
    {
        label: 'Morph(H)',
        id: 16,
        img: 'morph_logo.png',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 2810,
    },
    {
        label: 'Zircuit(T)',
        id: 17,
        img: 'zircuit_logo.svg',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 48899,
    },
    {
        label: 'Fhenix(T)',
        id: 18,
        img: 'fhenix_logo.png',
        width: 1.5,
        height: 1.625,
        isL2: true,
        networkID: 8008135,
    },
];
export const taikoHekla = {
    id: 167009,
    name: 'Taiko Hekla',
    network: 'tko-hekla',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://rpc.hekla.taiko.xyz'],
        },
        public: {
            http: ['https://rpc.hekla.taiko.xyz'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://hekla.taikoscan.network/',
        },
    },
};
export const xLayerTestnet = {
    id: 195,
    name: 'X Layer Testnet',
    network: 'XLayer Testnet',
    nativeCurrency: { name: 'OKB', symbol: 'OKB', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://testrpc.xlayer.tech'],
        },
        public: {
            http: ['https://testrpc.xlayer.tech'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://www.oklink.com/xlayer-test/',
        },
    },
};
export const xLayer = {
    id: 196,
    name: 'X Layer',
    network: 'X Layer',
    nativeCurrency: { name: 'OKB', symbol: 'OKB', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://rpc.xlayer.tech'],
        },
        public: {
            http: ['https://rpc.xlayer.tech'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://www.oklink.com/xlayer/',
        },
    },
};
export const taiko = {
    id: 167000,
    name: 'Taiko',
    network: 'Taiko',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://rpc.mainnet.taiko.xyz'],
        },
        public: {
            http: ['https://rpc.mainnet.taiko.xyz'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://taikoscan.network/',
        },
    },
};
export const modeMainnet = {
    id: 34443,
    name: 'Mode',
    network: 'Mode',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://mainnet.mode.network'],
        },
        public: {
            http: ['https://mainnet.mode.network'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://explorer.mode.network/',
        },
    },
};
export const amoy = {
    id: 80002,
    name: 'Amoy',
    network: 'Amoy',
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://rpc-amoy.polygon.technology/'],
        },
        public: {
            http: ['https://rpc-amoy.polygon.technology/'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://www.oklink.com/amoy',
        },
    },
};
export const cardona = {
    id: 2442,
    name: 'Cardona',
    network: 'Cardona',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://rpc.cardona.zkevm-rpc.com'],
        },
        public: {
            http: ['https://rpc.cardona.zkevm-rpc.com'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://testnet-zkevm.polygonscan.com/',
        },
    },
};
export const lineaSepolia = {
    id: 59141,
    name: 'Linea Sepolia',
    network: 'Linea Sepolia',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://rpc.sepolia.linea.build'],
        },
        public: {
            http: ['https://rpc.sepolia.linea.build'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://sepolia.lineascan.build/',
        },
    },
};
export const mantleTestnet = {
    id: 5003,
    name: 'Mantle Testnet',
    network: 'Mantle Testnet',
    nativeCurrency: { name: 'MNT', symbol: 'MNT', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://rpc.sepolia.mantle.xyz'],
        },
        public: {
            http: ['https://rpc.sepolia.mantle.xyz'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://explorer.sepolia.mantle.xyz/',
        },
    },
};
export const zkSyncSepolia = {
    id: 300,
    name: 'ZkSync Sepolia',
    network: 'ZkSync Sepolia',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://sepolia.era.zksync.dev'],
        },
        public: {
            http: ['https://sepolia.era.zksync.dev'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://sepolia.explorer.zksync.io/',
        },
    },
};
export const morphHolesky = {
    id: 2810,
    name: 'Morph Holesky',
    network: 'Morph Holesky',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://rpc-quicknode-holesky.morphl2.io'],
        },
        public: {
            http: ['https://rpc-quicknode-holesky.morphl2.io'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://explorer-holesky.morphl2.io/',
        },
    },
};
export const zircuitTestnet = {
    id: 48899,
    name: 'Zircuit Testnet',
    network: 'Zircuit Testnet',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://zircuit1.p2pify.com'],
        },
        public: {
            http: ['https://zircuit1.p2pify.com'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://explorer.zircuit.com/',
        },
    },
};
export const fhenixTestnet = {
    id: 8008135,
    name: 'Fhenix Testnet',
    network: 'Fhenix Testnet',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://api.helium.fhenix.zone'],
        },
        public: {
            http: ['https://api.helium.fhenix.zone'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://explorer.helium.fhenix.zone/',
        },
    },
};
export const NETWORKS = process.env.REACT_APP_IS_MAINNET === 'true' ? MAIN_NETWORKS : TEST_NETWORKS;
export const TOP_NETWORK_ID = process.env.REACT_APP_IS_MAINNET === 'true' ? 1 : 11155111;
const blockExplorers = {};
for (const network of currentNetworkList) {
    blockExplorers[network.chainId] = network.blockExplorerUrls;
}
export { blockExplorers };
export const usdcABI = [
    {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [
            {
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_spender',
                type: 'address',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'approve',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_from',
                type: 'address',
            },
            {
                name: '_to',
                type: 'address',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'transferFrom',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [
            {
                name: '',
                type: 'uint8',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '_owner',
                type: 'address',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                name: 'balance',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [
            {
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_to',
                type: 'address',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'transfer',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '_owner',
                type: 'address',
            },
            {
                name: '_spender',
                type: 'address',
            },
        ],
        name: 'allowance',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        payable: true,
        stateMutability: 'payable',
        type: 'fallback',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: 'owner',
                type: 'address',
            },
            {
                indexed: true,
                name: 'spender',
                type: 'address',
            },
            {
                indexed: false,
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'Approval',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: 'from',
                type: 'address',
            },
            {
                indexed: true,
                name: 'to',
                type: 'address',
            },
            {
                indexed: false,
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'Transfer',
        type: 'event',
    },
];
export const burnAbi = [
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenMessenger',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'uint32',
                name: 'destinationDomain',
                type: 'uint32',
            },
        ],
        name: 'DepositForBurnCalled',
        type: 'event',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                internalType: 'uint32',
                name: 'destinationDomain',
                type: 'uint32',
            },
            {
                internalType: 'bytes32',
                name: 'mintRecipient',
                type: 'bytes32',
            },
            {
                internalType: 'address',
                name: 'burnToken',
                type: 'address',
            },
        ],
        name: 'callDepositForBurn',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
